import { Box, Container, Link, Typography } from '@mui/material'
import { Image } from 'mui-image'
import { UserForm } from '~components/userInfo'
import logoBig from '~assets/images/logo-big-575x711.png'

export const Registration = () => {
  return (
    <Container component="section" sx={{ display: 'flex' }}>
      <Image style={{ width: '36rem' }} src={logoBig} wrapperStyle={{ flexBasis: 'content' }} />
      <Box
        id="formWrapper"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginY: '4rem'
        }}
      >
        <Typography variant="h4" color="primary.dark" sx={{ my: '1rem' }}>
          Anmeldeportal
        </Typography>
        <Typography variant="body1" color="secondary.contrastText">
          Erstellen Sie einen Account, um Ihre Anzeige zu verwalten.
        </Typography>
        <Typography color="secondary.contrastText">
          Haben Sie bereits ein Konto?{' '}
          <Link href="/login" color="primary.dark">
            Einloggen Seite
          </Link>
        </Typography>
        <UserForm
          info={
            <>
              <Typography color="primary.dark">
                <Typography component="span" fontWeight="bold">
                  Wichtig:
                </Typography>{' '}
                Die E-Mail-Adresse, die Sie für die Registrierung verwenden, kann in Zukunft nicht
                mehr geändert werden.
              </Typography>
            </>
          }
        />
      </Box>
    </Container>
  )
}
