import {
  Cartographic,
  Rectangle,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Viewer,
  Entity,
  BillboardGraphics,
  HeightReference,
  Cartesian3,
  VerticalOrigin,
  EntityCollection,
  GeoJsonDataSource,
  Cartesian2,
  Color,
  DistanceDisplayCondition,
  LabelStyle
} from 'cesium'
import { Entity as ResiumEntity } from 'resium'

import { SingleCartesian } from '.'
import { ReactNode } from 'react'

export const logoByCategory = (name: string, active: boolean) => {
  const status = active ? 'active' : 'default'
  switch (name) {
    case 'Einkaufen':
      return `Einkaufen_${status}.svg`
    case 'Gastronomie':
      return `Gastro_${status}.svg`
    case 'Dienstleistung':
      return `Dienst_${status}.svg`
    default:
      return 'voucher-marker.svg'
  }
}

export const logoByListingUse = (name: string, active: boolean) => {
  const status = active ? 'active' : 'default'
  switch (name) {
    case 'commercial':
      return `vacancy_commercial_${status}.svg`
    case 'residential':
      return `vacancy_residential_${status}.svg`
    case 'other':
      return `vacancy_andere_${status}.svg`
    default:
      return 'vacancy-marker.svg'
  }
}
export const activateShop = (entities: EntityCollection | undefined, entity: Entity | null) => {
  entities?.values?.map(ent => {
    let active = entity?.properties?.active.getValue()
    if (ent.id === entity?.id) {
      active = !active
    }
    const newBillboard = new BillboardGraphics({
      image: logoByCategory(ent?.properties?.category._value, active),
      heightReference: HeightReference.CLAMP_TO_GROUND,
      eyeOffset: new Cartesian3(0.0, 0.0, 0.0),
      verticalOrigin: VerticalOrigin.BOTTOM,
      sizeInMeters: true
    })
    if (ent && ent.billboard) ent.billboard = newBillboard
  })
}

export const activateVacancy = (entities: EntityCollection | undefined, entity: Entity | null) => {
  entities?.values?.map(ent => {
    let active = entity?.properties?.active.getValue()
    if (ent.id === entity?.id) {
      active = !active
    }
    const newBillboard = new BillboardGraphics({
      image: logoByListingUse(ent?.properties?.listingUse.getValue(), active),
      heightReference: HeightReference.CLAMP_TO_GROUND,
      eyeOffset: new Cartesian3(0.0, 0.0, 0.0),
      verticalOrigin: VerticalOrigin.BOTTOM,
      sizeInMeters: true
    })
    if (ent && ent.billboard) ent.billboard = newBillboard
  })
}

export const setCameraBounds = (cesElement: Viewer) => {
  const cityRectangle = Rectangle.fromDegrees(12.531737, 47.925232, 12.662356, 47.995628)

  const screenSpaceEventHandler = new ScreenSpaceEventHandler(cesElement.scene.canvas)

  screenSpaceEventHandler.setInputAction((click: SingleCartesian) => {
    const pickedPosition = cesElement.camera.pickEllipsoid(
      click.position,
      cesElement.scene.globe.ellipsoid
    )
    if (pickedPosition) {
      const cartographic = cesElement.camera.positionCartographic.clone()
      if (!Rectangle.contains(cityRectangle, cartographic)) {
        const closestPoint = closestPointOnRectangle(cityRectangle, cartographic)
        const newCartographic = new Cartographic(
          closestPoint.longitude,
          closestPoint.latitude,
          cartographic.height
        )
        const newCameraPosition =
          cesElement.scene.globe.ellipsoid.cartographicToCartesian(newCartographic)
        cesElement.camera.flyTo({
          destination: newCameraPosition,
          duration: 1.5
        })
      }
    }
  }, ScreenSpaceEventType.LEFT_DOWN)
}

const closestPointOnRectangle = (rectangle: Rectangle, cartographic: Cartographic) => {
  const west = rectangle.west
  const south = rectangle.south
  const east = rectangle.east
  const north = rectangle.north

  const longitude = cartographic.longitude
  const latitude = cartographic.latitude

  let x = longitude
  let y = latitude

  if (longitude < west) {
    x = west
  } else if (longitude > east) {
    x = east
  }

  if (latitude < south) {
    y = south
  } else if (latitude > north) {
    y = north
  }

  const closestCartographic = new Cartographic(x, y, cartographic.height)

  return closestCartographic
}

/**
 * Creates and returns a list of Entity components based on POI data source.
 * @param POIData POI data stored as GeoJSON in Cesium Ion
 */
export const getPointsOfInterest = async (POIData: GeoJsonDataSource) => {
  const eyeOffsetBillboard = new Cartesian3(0.0, 0.0, 0.0)
  const eyeOffsetLabel = new Cartesian3(0, 55, 0)

  const POIEntities: ReactNode[] = []

  const entities = POIData.entities.values
  for (let i = 0; i < entities.length; i++) {
    const entityProperties = entities[i].properties

    let displayCondition = new DistanceDisplayCondition(0, 500)
    if (
      (entityProperties?.category.valueOf() === 'Öffentliche Einrichtung' ||
        entityProperties?.poi_name.valueOf().includes('Bahnhaltestelle')) &&
      entityProperties?.poi_name.valueOf() !== 'Landespolizeidirektion'
    ) {
      displayCondition = new DistanceDisplayCondition(0, 3000)
    } else if (entityProperties?.category.valueOf() === 'Kultur') {
      displayCondition = new DistanceDisplayCondition(0, 1700)
    } else if (entityProperties?.no_neighbours.valueOf() === '0') {
      displayCondition = new DistanceDisplayCondition(0, 1100)
    }

    const POIProperties = {
      poi_uuid: entityProperties?.id.valueOf(),
      poi_name: entityProperties?.poi_name.valueOf(),
      website: entityProperties?.website.valueOf(),
      category: entityProperties?.category.valueOf(),
      no_neighbours: entityProperties?.no_neighbours.valueOf()
    }

    const entity = (
      <ResiumEntity
        key={entityProperties?.id.valueOf()}
        position={Cartesian3.fromDegrees(
          Number(entityProperties?.x.valueOf()),
          Number(entityProperties?.y.valueOf())
        )}
        billboard={{
          image: 'poi-marker.svg',
          heightReference: HeightReference.CLAMP_TO_GROUND,
          eyeOffset: eyeOffsetBillboard,
          verticalOrigin: VerticalOrigin.BOTTOM,
          sizeInMeters: true
        }}
        properties={POIProperties}
        label={{
          text: entityProperties?.poi_name.valueOf().replace(' ', '\n'),
          font: '700 16px system-ui',
          heightReference: HeightReference.RELATIVE_TO_GROUND,
          verticalOrigin: VerticalOrigin.BOTTOM,
          pixelOffset: new Cartesian2(0, 0),
          eyeOffset: eyeOffsetLabel,
          distanceDisplayCondition: displayCondition,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          fillColor: Color.WHITE,
          outlineColor: Color.BLACK,
          style: LabelStyle.FILL_AND_OUTLINE,
          outlineWidth: 3
        }}
      />
    )

    POIEntities.push(entity)
  }

  return POIEntities
}
