import {
  Viewer as ResiumViewer,
  Cesium3DTileset,
  CesiumComponentRef,
  ScreenSpaceCameraController,
  Entity as ResiumEntity
} from 'resium'
import {
  createWorldTerrain,
  Viewer,
  Cesium3DTileset as Cesium3DTilesetType,
  Color,
  IonResource,
  Ion,
  IonImageryProvider,
  Entity,
  Cesium3DTileStyle,
  GeoJsonDataSource,
  JulianDate,
  Cartesian3,
  HeightReference,
  VerticalOrigin
} from 'cesium'
import { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'
import { MapHomeButton, POIToggleButton } from '~components/mapButtons'
import { MapLocation, VoucherLocation } from '~services/locations'

import {
  setCameraBounds,
  mouseInstructions,
  touchInstructions,
  logoByCategory,
  activateShop,
  activateVacancy,
  getPointsOfInterest,
  CesiumViewerProps,
  logoByListingUse
} from '.'

Ion.defaultAccessToken = String(process.env.REACT_APP_CESIUM_TOKEN)
const terrainProvider = createWorldTerrain()
const imageryProvider = new IonImageryProvider({ assetId: 2 })
const ionResource = IonResource.fromAssetId(1873103)

/**
 * Wrapper component around the Cesium instance so that we have control over where and how to show it.
 */
export const CesiumViewer = memo(
  ({
    onClickBuilding,
    locations,
    voucherLocations,
    onClickVoucherSystem,
    initialPosition,
    setSelectedBuildingCoords
  }: CesiumViewerProps) => {
    const theme = useTheme()
    const ref = useRef<CesiumComponentRef<Viewer>>(null)
    const [tileSet, setTileSet] = useState<Cesium3DTilesetType>()
    const [ionResourceReady, setIonResourceReady] = useState<IonResource>()
    const [markerEntities, setMarkerEntities] = useState<ReactNode[]>([])
    const [voucherEntities, setVoucherEntities] = useState<ReactNode[]>([])
    const [markerPOIs, setMarkerPOIs] = useState<ReactNode[]>([])
    const [POIEntities, setPOIEntities] = useState<ReactNode[]>([])

    /**
     * Customizes the navigation menu
     * @param cesElement - the viewer
     */
    const renderCustomInstructions = (cesElement: Viewer | undefined) => {
      const customMouseInstructions = document.createElement('div')
      customMouseInstructions.innerHTML = mouseInstructions

      const customTouchInstructions = document.createElement('div')
      customTouchInstructions.innerHTML = touchInstructions
      const table = cesElement?.navigationHelpButton.container.firstChild?.lastChild?.childNodes

      table?.item(2)?.lastChild?.remove()
      table?.item(2)?.appendChild(customMouseInstructions.firstChild as Node)
      table?.item(3)?.lastChild?.remove()
      table?.item(3)?.appendChild(customTouchInstructions.firstChild as Node)
    }

    useEffect(() => {
      const fetchData = async () => {
        const POIResource = await IonResource.fromAssetId(1896179)
        const POIDataSource = await GeoJsonDataSource.load(POIResource)
        const POIEntities = await getPointsOfInterest(POIDataSource)
        setPOIEntities(POIEntities)
      }
      fetchData()
    }, [])

    /**
     * Prepare the map view after Cesium is ready: zoom to the center and set camera bounds
     * @param tileset - the Cesium3DTileset object we get after Cesium finishes loading
     */
    const handleCesiumOnReady = (tileset: Cesium3DTilesetType) => {
      const cesElement = ref.current?.cesiumElement

      cesElement ? setCameraBounds(cesElement) : null
      cesElement && cesElement.scene.camera.setView({ destination: initialPosition })

      setTileSet(tileset)
      renderCustomInstructions(cesElement)
    }

    /**
     * Track the selected Entity. We toggle the card component and building color when entity changes
     * @param entity - the Entity that was clicked
     */
    const handleSelectedEntityChange = (entity: Entity) => {
      if (entity && entity.properties?.gmlId) {
        // we have clicked on a marker

        // color the building underneath the marker
        if (tileSet) {
          tileSet.style = new Cesium3DTileStyle({
            color: {
              conditions: [
                [
                  "${OBJECT_ID} === '" + entity.properties.gmlId + "'",
                  "color('" + theme.palette.primary.dark + "', 1)"
                ]
              ]
            }
          })
        }

        const entityCoords = entity.position?.getValue(new JulianDate())
        entityCoords && setSelectedBuildingCoords && setSelectedBuildingCoords(entityCoords)

        // open the relevant card for it
        if (onClickBuilding) {
          activateVacancy(ref.current?.cesiumElement?.entities, entity)
          onClickBuilding(entity.properties?.listingIds._value)
        }
      } else {
        // we have clicked anywhere else on the map

        // "remove" the color of the building
        if (tileSet) {
          tileSet.style = new Cesium3DTileStyle({
            color: {
              conditions: [
                [
                  "${OBJECT_ID} === '" + entity?.properties?.gml_id + "'",
                  "color('" + Color.WHITE + "', 1)"
                ]
              ]
            }
          })
        }

        // and close the card
        if (onClickBuilding) {
          activateVacancy(ref.current?.cesiumElement?.entities, null)
          onClickBuilding(null)
        }
      }

      // in case we are in the stadtguthaben tab (we get a stadtguthaben uuid)
      if (entity && entity.properties?.stadtguthaben_uuid) {
        // we have clicked on a marker

        // open the relevant card for it
        if (onClickVoucherSystem) {
          activateShop(ref.current?.cesiumElement?.entities, entity)
          onClickVoucherSystem(entity.properties?.stadtguthaben_uuid._value)
        }
      } else {
        // we have clicked anywhere else on the map

        // and close the card
        if (onClickVoucherSystem) {
          activateShop(ref.current?.cesiumElement?.entities, null)
          onClickVoucherSystem(null)
        }
      }
    }

    /**
     * Re-centers the map
     */
    const handleHomeBtnClick = () => {
      if (tileSet) {
        const cesElement = ref.current?.cesiumElement
        cesElement &&
          cesElement.scene.camera.flyTo({
            destination: initialPosition,
            duration: 1.5
          })
      }
    }

    /**
     * Toggles POIs
     */
    const handleTogglePOIs = () => {
      if (markerPOIs.length === 0) {
        setMarkerPOIs(POIEntities)
      } else {
        setMarkerPOIs([])
      }
    }
    /**
     * When two items in the locations list have the same gml_id, it means that that building has multiple vacancies.
     * Aggregate these items and express the count in a new property called 'vacancies'.
     * @param locations - the list of locations
     */
    const countVacancies = (locations?: MapLocation[]) =>
      locations?.reduce((countedLocations, location) => {
        const { gml_id } = location

        if (countedLocations.has(gml_id)) {
          const existingLocation = countedLocations.get(gml_id)
          existingLocation && existingLocation.listingIds.push(existingLocation.listing_id)
        } else {
          countedLocations.set(gml_id, { ...location, listingIds: [location.listing_id] })
        }

        return countedLocations
      }, new Map<string, MapLocation & { listingIds: number[] }>())

    /**
     * Creates and returns a list of Entity components (the markers), based on the locations we get from
     * voucherLocations.
     * @param locations - the list of locations
     */
    const addVoucherLocations = useCallback((voucherLocations: VoucherLocation[]) => {
      const voucherEntities: ReactNode[] = []
      const eyeOffset = new Cartesian3(0.0, 0.0, 0.0)

      voucherLocations?.forEach((location: VoucherLocation) => {
        if (!(location && location.address && location.filter)) return
        const currentLocation = [location.address.lat, location.address.lon]

        const entity = (
          <ResiumEntity
            key={location.uuid}
            position={Cartesian3.fromDegrees(
              Number(currentLocation[1]),
              Number(currentLocation[0])
            )}
            billboard={{
              image: logoByCategory(location.filter.name, false),
              heightReference: HeightReference.CLAMP_TO_GROUND,
              eyeOffset: eyeOffset,
              verticalOrigin: VerticalOrigin.BOTTOM,
              sizeInMeters: true
            }}
            properties={{
              gmlId: location.gml_id,
              stadtguthaben_uuid: location.uuid,
              category: location.filter.name,
              active: false
            }}
          />
        )

        voucherEntities.push(entity)
      })

      return voucherEntities
    }, [])

    /**
     * Creates and returns a list of Entity components (the markers), based on the locations we get from
     * '/listings/locations' endpoint.
     * @param locations - the list of locations
     */
    const getEntitiesFromLocations = useCallback((locations: MapLocation[]) => {
      const markerEntities: ReactNode[] = []
      const locationsWithVacancies = countVacancies(locations)
      const eyeOffset = new Cartesian3(0.0, 0.0, 0.0)

      locationsWithVacancies?.forEach((location: MapLocation) => {
        const currentLocation = location.centroid.split(',')

        const entity = (
          <ResiumEntity
            key={location.listing_id}
            position={Cartesian3.fromDegrees(
              Number(currentLocation[1]),
              Number(currentLocation[0])
            )}
            billboard={{
              image: logoByListingUse(location.general_listing_use, false),
              heightReference: HeightReference.CLAMP_TO_GROUND,
              eyeOffset: eyeOffset,
              verticalOrigin: VerticalOrigin.BOTTOM,
              sizeInMeters: true
            }}
            properties={{
              gmlId: location.gml_id,
              listingIds: location.listingIds,
              listingUse: location.general_listing_use,
              active: false
            }}
          />
        )

        markerEntities.push(entity)
      })

      return markerEntities
    }, [])

    useEffect(() => {
      const markerEntities = locations && getEntitiesFromLocations(locations)
      markerEntities && setMarkerEntities(markerEntities)
    }, [locations, getEntitiesFromLocations])

    useEffect(() => {
      const voucherEntities = voucherLocations && addVoucherLocations(voucherLocations)
      voucherEntities && setVoucherEntities(voucherEntities)
    }, [voucherLocations, addVoucherLocations])
    /**
     * This is needed to prevent an error when reloading the map.
     * For now this seems to be only happening on hot reload, and it seems to be caused by Resium not using the latest
     * release of Cesium internally. At time of writing that would be 1.107, but Resium is on 1.103.0.
     * It might be worth using Cesium directly instead of Resium, as this might be a constant problem.
     */
    useEffect(() => {
      ionResource.then(resp => {
        setIonResourceReady(resp)
      })
    }, [])

    return ionResourceReady ? (
      <ResiumViewer
        ref={ref}
        timeline={false}
        animation={false}
        baseLayerPicker={false}
        infoBox={false}
        homeButton={false}
        fullscreenButton={false}
        vrButton={false}
        geocoder={false}
        scene3DOnly={true}
        projectionPicker={false}
        selectionIndicator={false}
        navigationHelpButton={true}
        navigationInstructionsInitiallyVisible={false}
        terrainProvider={terrainProvider}
        imageryProvider={imageryProvider}
        style={{ height: '75vh', position: 'relative' }}
        onSelectedEntityChange={handleSelectedEntityChange}
        sceneModePicker={false}
      >
        <ScreenSpaceCameraController maximumZoomDistance={5000} minimumZoomDistance={50} />
        <Cesium3DTileset url={ionResourceReady} onReady={handleCesiumOnReady} />

        <Box sx={{ position: 'absolute', bottom: '1rem', right: '1rem' }}>
          <MapHomeButton centerMap={handleHomeBtnClick} />
        </Box>
        <Box sx={{ position: 'absolute', bottom: '1rem', right: '3.5rem' }}>
          <POIToggleButton showPOIs={handleTogglePOIs} />
        </Box>

        {markerEntities}
        {markerPOIs}
        {voucherEntities}
      </ResiumViewer>
    ) : null
  }
)
