import { Button, Typography, MenuItem, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { StyledTextField } from '~components/input'
import { GeneralListingUseEnum, ListingTypesEnum } from '~services/listings'

import {
  FilterPanelInputValues,
  FilterTypesEnum,
  FilterPanelProps,
  filteringSchema,
  FilteringSchemaType
} from '.'
import { VoucherTypesEnum } from '~services/vouchers'

/**
 * Renders filters for narrowing the listings and locations on the map
 */
export const FilterPanel = ({ handleFiltering, category }: FilterPanelProps) => {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields }
  } = useForm<FilteringSchemaType>({
    resolver: zodResolver(filteringSchema),
    defaultValues: {
      min_price: '',
      max_price: '',
      min_usable_area: '',
      max_usable_area: '',
      listing_type: ListingTypesEnum.all,
      general_listing_use: GeneralListingUseEnum.all,
      gutscheine: VoucherTypesEnum.either
    },
    mode: 'onBlur'
  })

  /**
   * Checks whether the filter object coming from the form is "empty".
   * The "empty" values are:
   *    - in case of the dropdowns: "all",
   *    - in case of the number fields: undefined
   * @param obj - the filters
   */
  const areFiltersEmpty = (obj: Record<string, string | number | null | undefined>) => {
    for (const value of Object.values(obj)) {
      if (value !== 'all' && value !== undefined && value !== null && value !== '') {
        return false
      }
    }
    return true
  }

  /**
   * Handles the filter form submit.
   * We need to normalize the values first, so that the endpoint understands the filter object.
   * We want to send 'null' for "empty state". "All" in the case of the two dropdowns in the empty state.
   * At the end we also want to reset the form, but keep the introduced values. This way states like touched,
   * dirty, etc., are reset, but we still get to keep the values introduced by the user.
   * @param filters - the incoming filter values
   */
  const onSubmit: SubmitHandler<FilterPanelInputValues> = filters => {
    const dirtyKeys = Object.keys(dirtyFields)

    if (dirtyKeys.length === 0 || areFiltersEmpty(filters)) {
      handleFiltering({})
    } else {
      const normalizedFilters = Object.entries(filters).reduce(
        (normalized, [key, value]) => ({
          ...normalized,
          [key]: value === 'all' ? null : value ? value : null
        }),
        {} as Record<string, string | number | null>
      )

      handleFiltering(normalizedFilters)
    }
  }

  return category === FilterTypesEnum.listing ? (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2,
        borderRadius: '.75rem',
        padding: '1rem',
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'neutral.light',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        width: '100%'
      }}
    >
      {/* -------------------Vacancy Type----------------------- */}
      <Typography variant="h5" color="primary.dark" id="typLabel">
        Angebotstyp
      </Typography>
      <Controller
        name="listing_type"
        control={control}
        render={({ field }) => (
          <StyledTextField {...field} value={field.value} select size="small" variant="outlined">
            <MenuItem value={ListingTypesEnum.all}>Alle Angebote</MenuItem>
            <MenuItem value={ListingTypesEnum.rent}>Mieten</MenuItem>
            <MenuItem value={ListingTypesEnum.buy}>Kaufen</MenuItem>
          </StyledTextField>
        )}
      />

      {/* -------------------Contract Type----------------------- */}
      <Typography variant="h5" color="primary.dark" sx={{ marginTop: '.5rem' }} id="artLabel">
        Nutzungstyp
      </Typography>
      <Controller
        name="general_listing_use"
        control={control}
        render={({ field }) => (
          <StyledTextField {...field} select size="small" variant="outlined">
            <MenuItem value={GeneralListingUseEnum.all}>Alle Angebote</MenuItem>
            <MenuItem value={GeneralListingUseEnum.commercial}>Gewerblich</MenuItem>
            <MenuItem value={GeneralListingUseEnum.residential}>Residential</MenuItem>
            <MenuItem value={GeneralListingUseEnum.other}>Andere</MenuItem>
          </StyledTextField>
        )}
      />

      {/* ------------------- Size ----------------------- */}
      <Typography variant="h5" color="primary.dark" sx={{ marginTop: '.5rem' }}>
        Größe (m²)
      </Typography>
      <Controller
        name="min_usable_area"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            {...field}
            id="min_usable_area"
            placeholder="von m²"
            size="small"
            variant="outlined"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />

      <Controller
        name="max_usable_area"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            {...field}
            id="max_usable_area"
            error={Boolean(error)}
            helperText={error?.message}
            placeholder="bis m²"
            variant="outlined"
            size="small"
          />
        )}
      />

      {/* ------------------- Price ----------------------- */}
      <Typography variant="h5" color="primary.dark" sx={{ marginTop: '.5rem' }}>
        Preis (€)
      </Typography>
      <Controller
        name="min_price"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            id="min_price"
            placeholder="von €"
            variant="outlined"
            size="small"
          />
        )}
      />

      <Controller
        name="max_price"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            id="max_price"
            placeholder="bis €"
            variant="outlined"
            size="small"
          />
        )}
      />

      <Button
        type="submit"
        variant="contained"
        startIcon={<SearchIcon />}
        size="medium"
        sx={{
          color: 'white',
          textTransform: 'capitalize',
          marginTop: '1rem',
          backgroundColor: 'primary.main',
          '&:hover': { backgroundColor: 'primary.dark' }
        }}
      >
        Suchen
      </Button>
    </Box>
  ) : (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2,
        borderRadius: '.75rem',
        padding: '1rem',
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'neutral.light',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        width: '100%'
      }}
    >
      {/* -------------------Vacancy Type----------------------- */}
      <Typography variant="h5" color="primary.dark" id="typLabel">
        Gutscheine
      </Typography>
      <Controller
        name="gutscheine"
        control={control}
        render={({ field }) => (
          <StyledTextField {...field} value={field.value} select size="small" variant="outlined">
            <MenuItem value={VoucherTypesEnum.either}>Alle</MenuItem>
            <MenuItem value={VoucherTypesEnum.both}>Akzeptiert & Verkauft</MenuItem>
            <MenuItem value={VoucherTypesEnum.accepts}>Akzeptiert</MenuItem>
            <MenuItem value={VoucherTypesEnum.sells}>Verkauft</MenuItem>
          </StyledTextField>
        )}
      />
      <Button
        type="submit"
        variant="contained"
        startIcon={<SearchIcon />}
        size="medium"
        sx={{
          color: 'white',
          textTransform: 'capitalize',
          marginTop: '1rem',
          backgroundColor: 'primary.main',
          '&:hover': { backgroundColor: 'primary.dark' }
        }}
      >
        Suchen
      </Button>
    </Box>
  )
}
