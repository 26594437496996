import useSWR from 'swr'
import { Owner } from '~services/owner/types'
import { fetcher } from '~services/utils'

export const useOwner = (username: string | undefined) => {
  const { data, error, isLoading, mutate } = useSWR<Owner>(
    username ? `owners/${username}` : null,
    url => fetcher(url, 'GET')
  )

  return {
    owner: data,
    error,
    isLoading,
    mutate
  }
}
