import useSWR from 'swr'

import { FilterPanelInputValues } from '~components/filterPanel'
import { fetcher } from '~services/utils'
import { MapLocation } from '~services/locations'

export const useLocations = (
  locationFilters: FilterPanelInputValues = {},
  centroidFormat: 25832 | 4326 = 4326
) => {
  const { data, error, isLoading } = useSWR<MapLocation[]>(
    [`listings/locations?centroid_crs=${centroidFormat}`, locationFilters],
    ([url, body]: [string, FilterPanelInputValues]) =>
      fetcher<FilterPanelInputValues>(url, 'POST', body)
  )

  return {
    locations: data,
    error,
    isLoading
  }
}
