import { SyntheticEvent, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Tabs, Tab, Box, Typography, ClickAwayListener } from '@mui/material'
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material'

import { AccountMenu } from '.'

export const Navbar = () => {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState<number>(1)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isAccountMenuOpen = Boolean(anchorEl)
  const { user } = useAuthenticator(context => [context.user])

  /**
   * Set the active tab based on the URL rather than user interaction.
   */
  useEffect(() => {
    pathname.includes('/mieten')
      ? setActiveTab(1)
      : pathname.includes('/stadtgutschein')
      ? setActiveTab(2)
      : setActiveTab(3)
  }, [pathname])

  /**
   * Sets the new tab.
   * In case of the last tab (account) the "cursor" is going to stay on the previously active tab
   * @param event - the click event
   * @param newTab - newly selected tabs number
   */
  const handleTabChange = (event: SyntheticEvent, newTab: number) =>
    setActiveTab(prevTab => (newTab === 3 ? prevTab : newTab))

  const openAccountMenu = (event: SyntheticEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const closeAccountMenu = () => setAnchorEl(null)

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'white'
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="navigation bar"
        id="tabsComponent"
        sx={{
          '& .MuiTabs-indicator': { display: 'none' },
          '& .MuiTabs-flexContainer': { gap: 1 },
          '& .MuiTab-root': { color: 'primary.main', textTransform: 'unset' },
          '& .MuiTab-root.Mui-selected': { color: 'primary.dark' }
        }}
      >
        <Tab
          label={<Typography variant="h5">Gewerbefläche mieten</Typography>}
          component={Link}
          to={'/mieten'}
          sx={{ paddingLeft: 0, paddingBottom: 0, minHeight: 'auto' }}
          value={1}
        />
        <Tab
          label={<Typography variant="h5">Stadtgutschein einlösen</Typography>}
          component={Link}
          to={'/stadtgutschein'}
          sx={{ paddingBottom: 0, minHeight: 'auto' }}
          value={2}
        />
        <Tab
          key="accountMenuTab"
          label={
            <ClickAwayListener onClickAway={closeAccountMenu}>
              {user ? (
                <Typography variant="h5" onClick={openAccountMenu}>
                  {user.username}
                </Typography>
              ) : (
                <Typography variant="h5" onClick={openAccountMenu}>
                  Mein Profil
                </Typography>
              )}
            </ClickAwayListener>
          }
          value={3}
          aria-controls={isAccountMenuOpen ? 'accountMenu' : undefined}
          sx={{ marginLeft: 'auto', paddingRight: 0, minHeight: 'auto' }}
          icon={<AccountCircleIcon />}
          iconPosition="end"
        />
      </Tabs>

      <AccountMenu
        key="accountMenu"
        isOpen={isAccountMenuOpen}
        anchor={anchorEl}
        closeMenu={closeAccountMenu}
      />
    </Box>
  )
}
