import useSWR from 'swr'

import { FilterPanelInputValues } from '~components/filterPanel'
import { PaginatedResponse, Pagination } from '~types/ui'
import { fetcher } from '~services/utils'
import { ListingCard } from '~services/listings'

export const useFilteredListings = (
  listingFilters: FilterPanelInputValues = {},
  { limit, offset, previous }: Pagination = { limit: 6, offset: 0, previous: false }
) => {
  const { data, error, isLoading } = useSWR<PaginatedResponse<ListingCard[]>>(
    [`listings/search?limit=${limit}&offset=${offset}&previous=${previous}`, listingFilters],
    ([url, body]: [string, FilterPanelInputValues]) =>
      fetcher<FilterPanelInputValues>(url, 'POST', body)
  )

  return {
    listings: data,
    isLoading,
    error
  }
}
