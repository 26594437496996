import { type SyntheticEvent, useEffect, useState } from 'react'
import { Tab, Tabs, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as CubeIcon } from '~assets/images/svgs/cube.svg'
import { ReactComponent as UserIcon } from '~assets/images/svgs/userIcon.svg'
import { ReactComponent as InfoIcon } from '~assets/images/svgs/info-navigation.svg'
import { UserRoleEnum, useUserRole } from '~services/owner'

export const ProfileNavigation = () => {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState<number>(1)
  const { userRole } = useUserRole()

  const handleTabChange = (event: SyntheticEvent, newTab: number) => setActiveTab(newTab)

  /**
   * Set the active tab based on the URL rather than user interaction.
   */
  useEffect(() => {
    pathname.includes('/anzeigen-verwaltung') || pathname.includes('/meine-anzeigen')
      ? setActiveTab(1)
      : pathname.includes('/benutzer-verwaltung') || pathname.includes('/anzeige-aufgeben')
      ? setActiveTab(2)
      : pathname.includes('/mein-profil')
      ? setActiveTab(3)
      : userRole === UserRoleEnum.Admin
      ? setActiveTab(3)
      : setActiveTab(4)
  }, [pathname, userRole])

  return (
    <Tabs
      orientation="vertical"
      value={activeTab}
      onChange={handleTabChange}
      aria-label="navigation side panel"
      id="tabsComponent"
      sx={{
        minWidth: '12rem',
        '& .MuiTabs-indicator': { display: 'none' },
        '& .MuiTabs-flexContainer': { gap: 1 },
        '& .MuiTab-root': { color: 'secondary.contrastText', textTransform: 'unset' },
        '& .MuiTab-root.Mui-selected': { color: 'primary.dark' }
      }}
    >
      {userRole === UserRoleEnum.Admin
        ? [
            <Tab
              key={1}
              label={<Typography variant="h5">Alle Anzeigen</Typography>}
              component={Link}
              to="/profil/anzeigen-verwaltung"
              sx={{ paddingLeft: 0, paddingBottom: 0, alignSelf: 'start', minHeight: 'auto' }}
              value={1}
              icon={<CubeIcon />}
              iconPosition="start"
            />,

            <Tab
              key={2}
              label={<Typography variant="h5">Alle Benutzer</Typography>}
              component={Link}
              to="/profil/benutzer-verwaltung"
              sx={{ paddingLeft: 0, paddingBottom: 0, alignSelf: 'start', minHeight: 'auto' }}
              value={2}
              icon={<UserIcon />}
              iconPosition="start"
            />
          ]
        : [
            <Tab
              key={1}
              label={<Typography variant="h5">Meine Anzeigen</Typography>}
              component={Link}
              to="/profil/meine-anzeigen"
              sx={{ paddingLeft: 0, paddingBottom: 0, alignSelf: 'start', minHeight: 'auto' }}
              value={1}
              icon={<CubeIcon />}
              iconPosition="start"
            />,

            <Tab
              key={2}
              label={<Typography variant="h5">Anzeige aufgeben</Typography>}
              component={Link}
              to="/profil/anzeige-aufgeben"
              sx={{ paddingLeft: 0, paddingBottom: 0, alignSelf: 'start', minHeight: 'auto' }}
              value={2}
              icon={<UserIcon />}
              iconPosition="start"
            />,

            <Tab
              key={3}
              label={<Typography variant="h5">Mein Profil</Typography>}
              component={Link}
              to="/profil/mein-profil"
              sx={{ paddingLeft: 0, paddingBottom: 0, alignSelf: 'start', minHeight: 'auto' }}
              value={3}
              icon={<UserIcon />}
              iconPosition="start"
            />
          ]}

      <Tab
        label={<Typography variant="h5">Infoblatt</Typography>}
        component={Link}
        to="/info"
        sx={{ paddingLeft: 0, paddingBottom: 0, alignSelf: 'start', minHeight: 'auto' }}
        value={userRole === UserRoleEnum.Admin ? 3 : 4}
        icon={<InfoIcon />}
        iconPosition="start"
      />
    </Tabs>
  )
}
