import useSWRMutation from 'swr/mutation'

import { fetcher } from '~services/utils'

import { Address } from '.'
import { PaginatedResponse } from '~types/ui'

export const searchAddress = async (url: string, { arg }: { arg: string }) =>
  (await fetcher(`${url}?address=${arg}`, 'GET')) as PaginatedResponse<Address[]>

export const useAddressSearch = () => {
  const { trigger, data, error } = useSWRMutation(`addresses/search`, searchAddress)

  return {
    trigger,
    addresses: data,
    error
  }
}
