import useSWRMutation from 'swr/mutation'

import { ListingDetails, ListingsApprovalStatusEnum, UpdateListing } from '~services/listings/types'
import { fetcher, fileFetcher } from '~services/utils'
import useSWRImmutable from 'swr/immutable'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useUpdateListingStatus } from '~services/listings/useUpdateListingStatus'

export const updateListing = async (url: string, { arg }: { arg: UpdateListing }) =>
  (await fetcher(`listings/${arg.listingId}`, 'PATCH', arg.listingBody)) as ListingDetails

export const useUpdateListing = (listingId: number, images: File[] | null) => {
  const {
    data: updatedListing,
    error: updateListingError,
    trigger,
    isMutating
  } = useSWRMutation(listingId ? `listings/${listingId}` : null, updateListing)
  const { trigger: updateStatus } = useUpdateListingStatus(
    updatedListing ? updatedListing?.listing_id : undefined
  )

  //Attaches the images to the listing based on the listing ID and the user email from the logged-in user.
  const { user } = useAuthenticator(context => [context.user])
  const { error: uploadImagesError } = useSWRImmutable(
    updatedListing && updatedListing.listing_id && user && user.attributes
      ? [`listings/${updatedListing.listing_id}/attachments/`, images, user.attributes.email]
      : null,
    ([url, images, email]: [string, File[], string]) => {
      images.map(async image => {
        const data = new FormData()
        data.append('file', image)
        await fileFetcher(
          url + `?name=${image.name}&description=${image.name}&created_by=${email}`,
          'POST',
          data
        )
      })
    }
  )

  updateStatus({ newStatus: ListingsApprovalStatusEnum.submitted })

  return {
    updatedListing,
    error: { updateListingError, uploadImagesError },
    trigger,
    isMutating
  }
}
