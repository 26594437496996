import { Box, Typography, useTheme } from '@mui/material'
import { Image } from 'mui-image'
import { NavLink } from 'react-router-dom'

import bigLogo from '~assets/images/logo-big-575x711.png'

export const InfoPage = () => {
  const theme = useTheme()

  const linkStyle = {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Image src={bigLogo} width="30rem" />
      <Box>
        <Typography variant="h4" color="primary.dark">
          Gewerbefläche Vermieten
        </Typography>
        <Box sx={{ my: '2.5rem' }}>
          <Typography variant="h5" sx={{ my: '1rem', maxWidth: '35rem' }}>
            Wie kann ich mit meinem Antrag auf ein leerstehendes Gebäude veröffentlichen?
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: '35rem' }}>
            Reichen Sie die erforderlichen Unterlagen unter ihrem Zugang auf der Plattform
            "Gewerbefläche vermieten" ein. Dort werden Sie schrittweise durch die Antragsprozess
            geleitet. Sie haben die Möglichkeit, alle relevanten Informationen und Daten zum Gebäude
            einzupflegen oder entsprechende erläuternde Anmerkungen zu machen. Sobald Sie den Antrag
            eingereicht haben, wird dieser geprüft und entsprechend freigegeben oder abgelehnt.{' '}
            <NavLink to="/login" style={linkStyle}>
              Hier einloggen
            </NavLink>
            .
          </Typography>
        </Box>
        <Box sx={{ my: '2.5rem' }}>
          <Typography variant="h5" sx={{ my: '1rem' }}>
            Wann wird mein Angebot auf der Karte erscheinen?
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: '35rem' }}>
            Nachdem Ihr Angebot erfolgreich durch die Stadtverwaltung freigegeben wurde, wird es auf
            der Karte und in der Liste erscheinen. Auf der Karte wird Ihr Angebot durch einen roten
            Marker zu erkennen sein.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
