import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  Link as MUILink,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { Info as InfoIcon, LocationOn as LocationOnIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import { Carousel, DetailRow } from '~components/card'
import InfoBox from '~components/infoBox/InfoBox'
import {
  ListingsApprovalStatusEnum,
  ListingTypesEnum,
  useListing,
  useUpdateListingStatus
} from '~services/listings'
import { UserRoleEnum } from '~services/owner'

import { DetailMapComponent } from '.'
import { useUserRole } from '~services/owner/useUserRole'

const propertyDetailTranslations: Record<string, string> = {
  rent: 'Mieten',
  buy: 'Kaufen',
  house: 'Haus',
  apartment: 'Wohnung',
  shop: 'Seniorenwohnung',
  office: 'Büro',
  senior_apartment: 'Geschäft',
  storage_room: 'Lagerraum',
  production: 'Produktionsstätte',
  trade: 'Handel',
  garage: 'Garage',
  parking_space: 'Parkplatz',
  vacant_plot: 'Gastronomie',
  gastronomy: 'Bauland',
  other: 'Sonstiges',
  recently_renovated: 'Kürzlich renoviert',
  technical_infra_renovation_required: 'Technische Infrastruktur muss renoviert werden',
  interior_renovation_required: 'Innenrenovierung erforderlich',
  commercial: 'Gewerblich',
  residential: 'Residential',
  own_use: 'Eigennutzung',
  rented_out: 'Vermietet',
  vacant: 'Leer'
} as const

export const PropertyDetailPage = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const theme = useTheme()
  const { propertyId } = useParams()
  const { listing, isLoading, error } = useListing(Number(propertyId))
  const { trigger } = useUpdateListingStatus(listing?.listing_id)
  const { userRole } = useUserRole()

  if (isLoading) {
    return (
      <Container sx={{ my: '2rem', px: '3rem' }}>
        <Typography variant="h3" color="text.primary">
          Wird geladen...
        </Typography>
      </Container>
    )
  }

  if (error) {
    return (
      <Container sx={{ my: '2rem', px: '3rem' }}>
        <Typography variant="h3" color="text.primary">
          {error.name === '404'
            ? 'Eintrag wurde nicht gefunden.'
            : 'Es ist ein Fehler aufgetreten.'}
        </Typography>
      </Container>
    )
  }

  if (!listing) {
    return null
  }

  const handleStatusUpdate = (newStatus: ListingsApprovalStatusEnum) => async () => {
    await trigger({ newStatus })

    /**
     * Navigation is based on the "selected" status for the property:
     * Admins can "select" approved or rejected and Owners draft.
     */
    newStatus === ListingsApprovalStatusEnum.approved
      ? navigate(`/mieten/${propertyId}`)
      : newStatus === ListingsApprovalStatusEnum.rejected
      ? navigate('/profil/anzeigen-verwaltung')
      : navigate('/profil/anzeige-aufgeben', { state: { propertyId } })
  }

  return (
    <Container sx={{ my: '2rem', px: '3rem' }}>
      <Breadcrumbs separator="|" aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
          Übersicht
        </Link>
        <Link to="/mieten" style={{ color: 'inherit', textDecoration: 'none' }}>
          Gewerbeflächen mieten
        </Link>
        <Typography color="text.primary">{listing.title}</Typography>
      </Breadcrumbs>
      <Grid container gap={5}>
        <Grid item xs={12} lg={6}>
          {listing && listing.attachments.length > 0 && (
            <Carousel images={listing.attachments.map(o => o.s3_presigned_url)} />
          )}
          <Typography textTransform="uppercase" fontWeight="bold" sx={{ mt: '1rem' }}>
            {listing.title}
          </Typography>
          <DetailRow
            icon={<LocationOnIcon color="primary" />}
            information={`${listing.address?.streetname_number}, ${listing.address?.zipcode} ${listing.address?.city}`}
          />
          <InfoBox>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography variant="h5">{listing.usable_floor_area} m²</Typography>
                  </TableCell>

                  <TableCell sx={{ border: 'none' }}>
                    <Typography variant="h5">{`${listing.price.toLocaleString('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2
                    })} €`}</Typography>
                  </TableCell>
                  {listing.service_costs && (
                    <TableCell sx={{ border: 'none' }}>
                      <Typography variant="h5">{listing.service_costs} €</Typography>
                    </TableCell>
                  )}
                  {listing.energy_certificate.energy_consumption && (
                    <TableCell sx={{ border: 'none' }}>
                      <Typography variant="h5">
                        {listing.energy_certificate.energy_consumption} kWh/m²
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography>Nutzbare Fläche</Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography>
                      {listing.listing_type === ListingTypesEnum.rent ? 'Kaltmiete' : 'Kaufpreis'}
                    </Typography>
                  </TableCell>
                  {listing.service_costs && (
                    <TableCell sx={{ border: 'none' }}>
                      <Typography>Nebenkosten</Typography>
                    </TableCell>
                  )}
                  <TableCell sx={{ border: 'none' }}>
                    <Typography>
                      Energieffizienzklasse {listing.energy_certificate.label}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </InfoBox>
          <InfoBox title="Hauptkriterien">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    <Typography sx={{ wordBreak: 'break-word' }}>Miete / Kaufobjekt</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing.listing_type
                      ? propertyDetailTranslations[listing.listing_type]
                      : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Verfügbar ab
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing.available_from
                      ? new Date(listing.available_from).toLocaleDateString('de-DE')
                      : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Immobilientyp
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing?.general_listing_use
                      ? propertyDetailTranslations[listing.general_listing_use]
                      : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Nutzbare Fläche
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    {listing.usable_floor_area} m²
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Tooltip
                      title={
                        <Box
                          sx={{
                            backgroundColor: 'white',
                            color: 'primary.main',
                            border: '.05rem solid',
                            borderColor: 'neutral.light',
                            borderRadius: '0.5rem',
                            padding: '.5rem'
                          }}
                        >
                          <Box sx={{ display: 'flex', p: '.25rem' }}>
                            <Typography>
                              Summe der Grundfläche mit Nutzungen derjenige Teil der
                              Netto-Raumfläche, der der Nutzung des Bauwerks aufgrund seiner
                              Zweckbestimmung dient. (DIN 277)
                            </Typography>
                          </Box>
                        </Box>
                      }
                    >
                      <IconButton sx={{ py: 0 }}>
                        <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                {listing.listing_type ===
                  Object.values(ListingTypesEnum).find(
                    val => val === ListingTypesEnum.rent
                  )?.[0] && (
                  <Grid container my={1}>
                    <Grid item xs={12} lg={6}>
                      Mietdauer
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {listing.additional_metadata.available_to ? 'Befristet' : 'Unbefristet'}
                    </Grid>
                  </Grid>
                )}

                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Status
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing?.owner_relationship
                      ? propertyDetailTranslations[listing.owner_relationship]
                      : 'N/A'}
                  </Grid>
                </Grid>

                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Immobiliennutzung
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing.listing_use ? propertyDetailTranslations[listing.listing_use] : 'N/A'}
                  </Grid>
                </Grid>

                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Grundstücksfläche
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    {listing.plot_area ? `${listing.plot_area} m²` : 'N/A'}
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Tooltip
                      title={
                        <Box
                          sx={{
                            backgroundColor: 'white',
                            color: 'primary.main',
                            border: '.05rem solid',
                            borderColor: 'neutral.light',
                            borderRadius: '0.5rem',
                            padding: '.5rem'
                          }}
                        >
                          <Box sx={{ display: 'flex', p: '.25rem' }}>
                            <Typography>
                              Fläche, die durch die Grundstücksgrenzen gebildet wird und die im
                              Liegenschaftskataster sowie im Grundbuch ausgewiesen ist. (DIN 277)
                            </Typography>
                          </Box>
                        </Box>
                      }
                    >
                      <IconButton sx={{ py: 0 }}>
                        <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </InfoBox>
          <InfoBox title="Weitere Kriterien">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Zimmeranzahl
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing.num_rooms ? listing.num_rooms : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Denkmalschutz
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {typeof listing.additional_metadata.monument_status === 'boolean'
                      ? listing.additional_metadata.monument_status
                        ? 'Ja'
                        : 'Nein'
                      : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Sanierungsjahr
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing.renovation_year ? listing.renovation_year : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Keller
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {typeof listing.additional_metadata.has_basement === 'boolean'
                      ? listing.additional_metadata.has_basement
                        ? 'Ja'
                        : 'Nein'
                      : 'N/A'}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Etage
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {`${listing.floor ? listing.floor : 'N/A'} von ${
                      listing.num_floors ? listing.num_floors : 'N/A'
                    }`}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Baujahr
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing.additional_metadata.construction_year
                      ? listing.additional_metadata.construction_year
                      : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Möbiliert
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {typeof listing.additional_metadata.furnished === 'boolean'
                      ? listing.additional_metadata.furnished
                        ? 'Ja'
                        : 'Nein'
                      : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Dachboden
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {typeof listing.additional_metadata.has_attic === 'boolean'
                      ? listing.additional_metadata.has_attic
                        ? 'Ja'
                        : 'Nein'
                      : 'N/A'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </InfoBox>
          <InfoBox title="Kosten">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    <Typography>
                      {listing.listing_type === ListingTypesEnum.rent ? 'Kaltmiete' : 'Kaufpreis'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {`${listing.price.toLocaleString('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2
                    })} €`}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Nebenkosten
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing.service_costs ? `${listing.service_costs} €` : 'N/A'}
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    Preis pro m²
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {listing.price && listing.usable_floor_area
                      ? `${(listing.price / listing.usable_floor_area).toLocaleString('de-DE', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        })} €`
                      : 'N/A'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </InfoBox>
          <InfoBox title="Energieausweis">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid item>
                  <Grid container my={1}>
                    <Grid item xs={12} md={6}>
                      Energieklasse
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {listing.energy_certificate?.label
                        ? listing.energy_certificate?.label
                        : 'N/A'}
                    </Grid>
                  </Grid>
                  {listing.energy_certificate.energy_consumption && (
                    <Grid container my={1}>
                      <Grid item xs={12} md={6}>
                        Energiebedarf
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {listing.energy_certificate.energy_consumption} kWh/m²
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item>
                  <Grid container my={1}>
                    <Grid item xs={12} md={6}>
                      Heizungsart
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {listing.additional_metadata.heating_type
                        ? listing.additional_metadata.heating_type
                        : 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      Ausstellungsjahr
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {listing.energy_certificate.issuing_date
                        ? new Date(listing.energy_certificate.issuing_date).getFullYear()
                        : 'N/A'}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </InfoBox>

          <InfoBox title="Städtebau Bewertung  (1 - niedrige Qualität, 5 - hohe Qualität)">
            <Grid container rowSpacing={2}>
              <Grid item xs={8}>
                Inneres Erscheinungsbild
              </Grid>
              <Grid item xs={2}>
                {listing.additional_metadata.interior_appearance
                  ? listing.additional_metadata.interior_appearance
                  : 'N/A'}
              </Grid>
              <Grid item xs={6} lg={2}>
                <Tooltip
                  title={
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        color: 'primary.main',
                        border: '.05rem solid',
                        borderColor: 'neutral.light',
                        borderRadius: '0.5rem',
                        padding: '.5rem'
                      }}
                    >
                      <Box sx={{ display: 'flex', p: '.25rem' }}>
                        <List>
                          <ListItem>
                            <ListItemText primary="5: 3fach-Verglasung (Fenster/Türen), Fußbodenheizung, Echtholzparkett oder Steinböden, Schreinereinbauküche, Einbruchsicherung" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="3: Fliesen- oder Parkettboden, sauber/gereinigt, bezugsfertig, 2fach-Verglasung (Fenster/Türen)" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="1: renovierungsbedürftig, mangelhafte oder fehlerhafte Ausstattung, zugige Fenster und Türen" />
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  }
                >
                  <IconButton sx={{ py: 0 }}>
                    <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={8}>
                Äußeres Erscheinungsbild
              </Grid>
              <Grid item xs={2}>
                {listing.additional_metadata.neighbourhood_quality
                  ? listing.additional_metadata.neighbourhood_quality
                  : 'N/A'}
              </Grid>
              <Grid item xs={6} lg={2}>
                <Tooltip
                  title={
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        color: 'primary.main',
                        border: '.05rem solid',
                        borderColor: 'neutral.light',
                        borderRadius: '0.5rem',
                        padding: '.5rem'
                      }}
                    >
                      <Box sx={{ display: 'flex', p: '.25rem' }}>
                        <List>
                          <ListItem>
                            <ListItemText primary="5: neu/neuwertig bzw. frisch renoviert" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="3: Gebäudehülle gepflegt und intakt, nicht renovierungsbedürftiger Zustand " />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="1: renovierungsbedürftig, mangelhafte Gebäudehülle" />
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  }
                >
                  <IconButton sx={{ py: 0 }}>
                    <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={8}>
                Versorgungstechnik
              </Grid>
              <Grid item xs={2}>
                {listing.additional_metadata.supply_technology
                  ? listing.additional_metadata.supply_technology
                  : 'N/A'}
              </Grid>
              <Grid item xs={6} lg={2}>
                <Tooltip
                  title={
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        color: 'primary.main',
                        border: '.05rem solid',
                        borderColor: 'neutral.light',
                        borderRadius: '0.5rem',
                        padding: '.5rem'
                      }}
                    >
                      <Box sx={{ display: 'flex', p: '.25rem' }}>
                        <List>
                          <ListItem>
                            <ListItemText primary="5: neuester Technikstand mit regenerativer Energie, wie bsp. Photovoltaikanlage, Solarthermie, Heizung neu bzw. max. 3 Jahre alt, Brauchwasseranlage" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="3: Versorgungstechnik stetig gewartet, gut funktionierend, zwischen 10 und 20 Jahre alt" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="1: Versorgungsleitungen für Strom/Wasser älter als 30 Jahre, keine Heizung oder älter als 30 Jahre, Nachtspeicherofen" />
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  }
                >
                  <IconButton sx={{ py: 0 }}>
                    <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={8}>
                Internetgeschwindigkeit
              </Grid>
              <Grid item xs={2}>
                {listing.additional_metadata.internet_mbits
                  ? `${listing.additional_metadata.internet_mbits} mbits/sec`
                  : 'N/A'}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Weitere Informationen</Typography>
              </Grid>
              <Grid item xs={12}>
                {listing.additional_metadata.appearance_comments
                  ? listing.additional_metadata.appearance_comments
                  : 'N/A'}
              </Grid>
            </Grid>
          </InfoBox>

          <InfoBox title="Sonstige Informationen">
            {listing.additional_metadata.comments ? listing.additional_metadata.comments : 'N/A'}
          </InfoBox>
        </Grid>
        <Grid item xs={12} lg={5}>
          <InfoBox title="Ihr:e Ansprechpartner:in" noMarginTop={true}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <Typography fontWeight="bold">
                  {listing.owner.firstname} {listing.owner.lastname}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MUILink
                  href={`mailto:${listing.owner.email}?subject=Anfrage zu Anzeige ${listing.title}`}
                >
                  {listing.owner.email}
                </MUILink>
              </Grid>
              {listing.owner.company_name && (
                <Grid item xs={12}>
                  <Typography fontWeight="bold">{listing.owner.company_name}</Typography>
                </Grid>
              )}
            </Grid>
          </InfoBox>
          <InfoBox title="Objektbeschreibung">
            <Typography>{listing.description}</Typography>
          </InfoBox>
          {state && state.gmlId ? (
            <DetailMapComponent
              gmlId={state.gmlId}
              selectedBuildingCoords={state.selectedBuildingCoords}
            />
          ) : null}
          <InfoBox title="Lage Bewertung  (1 - mangelhaft, 5 - sehr gut) ">
            <Grid container rowSpacing={2}>
              <Grid item xs={8}>
                Erreichbarkeit PKW und ÖPNV:
              </Grid>
              <Grid item xs={2}>
                {listing.additional_metadata.accessibility
                  ? listing.additional_metadata.accessibility
                  : 'N/A'}
              </Grid>
              <Grid item xs={6} lg={2}>
                <Tooltip
                  title={
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        color: 'primary.main',
                        border: '.05rem solid',
                        borderColor: 'neutral.light',
                        borderRadius: '0.5rem',
                        padding: '.5rem'
                      }}
                    >
                      <Box sx={{ display: 'flex', p: '.25rem' }}>
                        <List>
                          <ListItem>
                            <ListItemText primary="5: eigener Stellplatz/Garage, Tiefgarage, E-Lade-Möglichkeit, Car-Sharing, ÖPNV-Haltestelle mit hoher Taktfrequenz fußläufig in max. 5 min. erreichbar" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="3: fest zugewiesener Stellplatz in der unmittelbaren Nähe, ÖPNV-Anbindung täglich erreichbar" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="1: kein eigener Stellplatz, keine eigene Zufahrt, keine Parkmöglichkeit im Umkreis < 500 m, kein ÖPNV-Anschluss" />
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  }
                >
                  <IconButton sx={{ py: 0 }}>
                    <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Weitere Informationen</Typography>
              </Grid>
              <Grid item xs={12}>
                {listing.additional_metadata.accessibility_comments
                  ? listing.additional_metadata.accessibility_comments
                  : 'N/A'}
              </Grid>
            </Grid>
          </InfoBox>

          {userRole !== UserRoleEnum.Read ? (
            <Grid item xs={12}>
              <Paper elevation={8} sx={{ padding: '1rem' }}>
                <Typography variant="h5" paddingLeft="1rem" marginBottom="1rem">
                  {userRole === UserRoleEnum.Admin
                    ? 'Den Eintrag veröffentlichen?'
                    : 'Möchten Sie den Eintrag bearbeiten und erneut einreichen?'}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: userRole === UserRoleEnum.Admin ? 'space-between' : 'center',
                    paddingX: '1rem'
                  }}
                >
                  {listing?.approval_status !== ListingsApprovalStatusEnum.approved &&
                  userRole === UserRoleEnum.Admin ? (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{ minWidth: '10rem' }}
                        onClick={handleStatusUpdate(ListingsApprovalStatusEnum.approved)}
                      >
                        Veröffentlichen
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        sx={{ minWidth: '10rem' }}
                        onClick={handleStatusUpdate(ListingsApprovalStatusEnum.rejected)}
                      >
                        Ablehnen
                      </Button>
                    </>
                  ) : listing?.approval_status !== ListingsApprovalStatusEnum.approved &&
                    userRole === UserRoleEnum.Write ? (
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      sx={{ minWidth: '10rem' }}
                      onClick={handleStatusUpdate(ListingsApprovalStatusEnum.draft)}
                    >
                      Bearbeiten
                    </Button>
                  ) : null}
                </Box>
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  )
}
