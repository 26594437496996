import { useRef, useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { Viewer as ResiumViewer, Cesium3DTileset, CesiumComponentRef } from 'resium'
import {
  createWorldTerrain,
  Viewer,
  Cesium3DTileStyle,
  Ion,
  IonResource,
  Rectangle,
  Cartographic
} from 'cesium'

import { DetailMapComponentProps } from './types'

const terrainProvider = createWorldTerrain()
Ion.defaultAccessToken = String(process.env.REACT_APP_CESIUM_TOKEN)
const ionResource = IonResource.fromAssetId(1873103)

export const DetailMapComponent = ({ gmlId, selectedBuildingCoords }: DetailMapComponentProps) => {
  const boxEl = useRef<HTMLDivElement>(null)
  const [mapWidth, setMapWidth] = useState(0)

  useEffect(() => {
    if (boxEl.current) {
      setMapWidth(boxEl.current.getBoundingClientRect().width)
    }
  }, [])

  const ref = useRef<CesiumComponentRef<Viewer>>(null)

  useEffect(() => {
    if (ref.current && ref.current.cesiumElement) {
      ref.current.cesiumElement.scene.screenSpaceCameraController.enableZoom = false
    }
  }, [])

  const handleCesiumOnReady = () => {
    const cesElement = ref.current?.cesiumElement

    const coordsCartographic = Cartographic.fromCartesian(selectedBuildingCoords)

    const destinationRectangle = new Rectangle(
      coordsCartographic.longitude - 0.00007,
      coordsCartographic.latitude - 0.00007,
      coordsCartographic.longitude + 0.00007,
      coordsCartographic.latitude + 0.00007
    )

    cesElement?.scene.camera.setView({
      destination: destinationRectangle
    })
  }

  return (
    <Box ref={boxEl}>
      <ResiumViewer
        ref={ref}
        timeline={false}
        animation={false}
        baseLayerPicker={false}
        infoBox={false}
        homeButton={false}
        fullscreenButton={false}
        vrButton={false}
        geocoder={false}
        scene3DOnly={true}
        projectionPicker={false}
        selectionIndicator={false}
        navigationHelpButton={false}
        navigationInstructionsInitiallyVisible={false}
        terrainProvider={terrainProvider}
        requestRenderMode={true}
        maximumRenderTimeChange={Infinity}
        style={{
          width: `${mapWidth}px`,
          height: `${mapWidth}px`,
          userSelect: 'none',
          pointerEvents: 'none'
        }}
      >
        <Cesium3DTileset
          url={ionResource}
          onReady={handleCesiumOnReady}
          style={
            new Cesium3DTileStyle({
              color: {
                conditions: [["${OBJECT_ID} === '" + String(gmlId) + "'", `color('#F0645D', 1)`]]
              }
            })
          }
        />
      </ResiumViewer>
    </Box>
  )
}
