import { useEffect, useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { UserRoleEnum } from '~services/owner'

export const useUserRole = () => {
  const { user } = useAuthenticator(context => [context.user])
  const [userRole, setUserRole] = useState<UserRoleEnum>(UserRoleEnum.Read)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    try {
      user &&
        // The types of amplify are messed up. There has been an open issue about it since 2020
        // https://github.com/aws-amplify/amplify-js/issues/4927
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        user.getSession((error: Error, session: any) => {
          !error && setUserRole(session?.accessToken?.payload['cognito:groups'][0])
          setIsLoading(false)
        })
    } catch (error) {
      console.log('Error retrieving user', error)
    } finally {
      setIsLoading(false)
    }
  }, [user])

  return {
    userRole,
    isLoading
  }
}
