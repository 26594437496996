import { useState, memo, useMemo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import {
  AccessTime as AccessTimeIcon,
  LocalOfferOutlined as LocalOfferIcon,
  LocationOnOutlined as LocationOnIcon
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { Cartesian3 } from 'cesium'

import { ReactComponent as AreaIcon } from '~assets/images/svgs/area-icon.svg'
import { CesiumViewer, SelectEntityHandler } from '~components/cesiumViewer'
import { CardComponent } from '~components/card'
import { OrientationEnum } from '~types/ui'
import { useLocations } from '~services/locations'
import { useListing } from '~services/listings'

import { MapViewProps } from '.'

/**
 * Renders the map view
 */
export const MapView = memo(({ locationFilters }: MapViewProps) => {
  const navigate = useNavigate()
  const [selectedBuilding, setSelectedBuilding] = useState<number | null>(null)
  const [selectedBuildingCoords, setSelectedBuildingCoords] = useState<Cartesian3 | null>(null)
  const [showMultiple, setShowMultiple] = useState<boolean>(false)
  const [totalCards, setTotalCards] = useState<number>(1)
  const [currentCard, setCurrentCard] = useState<number>(1)
  const [cardListings, setCardListings] = useState<number[] | null>([])
  const { locations } = useLocations(locationFilters)
  const { listing, isLoading: isListingLoading } = useListing(selectedBuilding)
  const initialPosition = useMemo(() => new Cartesian3(4177300, 933235, 4715550), [])

  const close = () => {
    setSelectedBuilding(null)
  }

  /**
   * Save the clicked entity to local state, so we can show the card component for it.
   * We compare the received entityId to what we have saved previously. If they match, it means that
   * we want to deselect the entity.
   * @param locationIds - the ID of the clicked entity received from the viewer
   */
  const handleBuildingClick: SelectEntityHandler = locationIds => {
    setCardListings(locationIds)
    if (locationIds && locationIds.length > 1) {
      setShowMultiple(true)
      setTotalCards(locationIds.length)
    }

    if (locationIds && locationIds[0]) {
      locationIds[0] !== selectedBuilding
        ? setSelectedBuilding(locationIds[0])
        : setSelectedBuilding(null)
    } else {
      setSelectedBuilding(null)
    }
  }

  const prevCard = () => {
    const newCard = currentCard - 1 < 1 ? totalCards : currentCard - 1

    setCurrentCard(newCard)
    cardListings && setSelectedBuilding(cardListings[newCard - 1])
  }

  const nextCard = () => {
    const newCard = currentCard + 1 === totalCards + 1 ? 1 : currentCard + 1

    setCurrentCard(newCard)
    cardListings && setSelectedBuilding(cardListings[newCard - 1])
  }

  const goToCardDetails = (listingId: number) => () => {
    const gmlId = locations?.find(o => o.listing_id === listingId)?.gml_id
    navigate(`/mieten/${listingId}`, { state: { gmlId, selectedBuildingCoords } })
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <CesiumViewer
        onClickBuilding={handleBuildingClick}
        locations={locations}
        initialPosition={initialPosition}
        setSelectedBuildingCoords={setSelectedBuildingCoords}
      />
      {selectedBuilding && listing && (
        <Box sx={{ position: 'absolute', top: '1rem', left: '1rem' }}>
          <CardComponent
            popup={true}
            orientation={OrientationEnum.vertical}
            actionBtnText="Details anzeigen"
            images={listing.attachments?.map(o => o.s3_presigned_url)}
            title={listing.title}
            showMultiple={showMultiple}
            total={totalCards}
            current={currentCard}
            closeWindow={close}
            prevCard={prevCard}
            nextCard={nextCard}
            actionBtnCallback={goToCardDetails(listing.listing_id)}
          >
            {isListingLoading ? (
              'wird geladen...'
            ) : (
              <Grid container direction="column" gap={1} mb="1rem">
                <Grid item>
                  <Grid container direction="row" gap={2} alignItems="center">
                    <LocationOnIcon sx={{ color: 'primary.dark' }} />{' '}
                    <Typography>
                      {listing.address?.streetname_number}, {listing.address?.city}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="row" gap={2} alignItems="center">
                    <AreaIcon /> <Typography>€{listing?.price}</Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="row" gap={2} alignItems="center">
                    <LocalOfferIcon sx={{ color: 'primary.dark' }} />{' '}
                    <Typography>{listing?.usable_floor_area} m²</Typography>
                  </Grid>
                </Grid>

                {listing?.available_from && (
                  <Grid item>
                    <Grid container direction="row" gap={2} alignItems="center">
                      <AccessTimeIcon sx={{ color: 'primary.dark' }} />{' '}
                      <Typography>
                        {new Date(listing.available_from).toLocaleDateString('de-DE')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </CardComponent>
        </Box>
      )}
    </Box>
  )
})
