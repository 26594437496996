import { ChangeEvent, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Typography
} from '@mui/material'
import { StyledTextField } from '~components/input'
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material'

import {
  Owner,
  OwnerApprovalStatusEnum,
  OwnerFilters,
  OwnerTypeEnum,
  useOwners
} from '~services/owner'
import { Pagination } from '~types/ui'
import useSWRMutation from 'swr/mutation'
import { updateOwnerStatus } from '~services/owner/updateOwnerStatus'

export const UserApprovalList = () => {
  const [pagination, setPagination] = useState<Pagination>({ limit: 6, offset: 0, previous: false })
  const [ownerFilters, setOwnerFilters] = useState<OwnerFilters>({
    approval_status: OwnerApprovalStatusEnum.pending
  })
  const { owners, isLoading, mutate } = useOwners(ownerFilters, pagination)
  const { trigger } = useSWRMutation('owners/', updateOwnerStatus)
  const [pageNumber, setPageNumber] = useState(1)

  const handleStatusFiltering = (event: ChangeEvent<HTMLInputElement>) =>
    setOwnerFilters(prev => ({
      ...prev,
      approval_status:
        OwnerApprovalStatusEnum[event.target.value as keyof typeof OwnerApprovalStatusEnum]
    }))

  const handlePageChange = (direction: 'previous' | 'next') => () => {
    if (direction === 'previous') {
      setPagination(prev => ({
        ...prev,
        previous: true,
        offset: owners?.offset_previous ? owners.offset_previous : 0
        // previous: true
      }))
      setPageNumber(pageNumber - 1)
    } else {
      setPagination(prev => ({
        ...prev,
        previous: false,
        offset: owners?.offset_next ? owners.offset_next : 0
      }))
      setPageNumber(pageNumber + 1)
    }
  }

  const handleStatusUpdate = (newStatus: OwnerApprovalStatusEnum, username: string) => async () => {
    await trigger({ newStatus, username })
    await mutate()
  }

  const renderUserCards = (owner: Owner) => (
    <Paper
      elevation={8}
      key={owner.owner_id}
      sx={{ maxWidth: '30rem', '&:not(:last-child)': { marginBottom: '1rem' } }}
    >
      <Grid container direction="row" padding="1rem">
        <Grid container direction="column" xs={6}>
          <Typography variant="h6">{`${owner.firstname} ${owner.lastname}`}</Typography>
          <Typography marginBottom={owner.company_name ? 0 : '1rem'}>
            {owner.owner_type === OwnerTypeEnum.commercial
              ? 'Gewerbliche:r Anbieter:in'
              : 'Private:r Anbieter:in'}
          </Typography>
          {owner.owner_type === OwnerTypeEnum.commercial && owner.company_name ? (
            <Typography marginBottom="1rem">{owner.company_name}</Typography>
          ) : null}

          <Typography>{`${owner.streetname} ${owner.housenumber}${
            owner.houseletter ? ` ${owner.houseletter}` : ''
          }`}</Typography>
          <Typography>{`${owner.zipcode} ${owner.city},${owner.state ? ` ${owner.state}` : ''} ${
            owner.country
          }`}</Typography>

          <Typography>{owner.email}</Typography>
          <Typography>{owner.phone}</Typography>
          {owner.website ? <Typography>{owner.website}</Typography> : null}
        </Grid>
        <Grid
          container
          direction="column"
          xs={6}
          alignContent="flex-end"
          alignSelf={
            ownerFilters.approval_status === OwnerApprovalStatusEnum.pending ? 'flex-end' : 'center'
          }
        >
          {ownerFilters.approval_status !== OwnerApprovalStatusEnum.approved ? (
            <Grid item>
              <Button
                variant="contained"
                color="success"
                sx={{
                  minWidth: '10rem',
                  marginBottom:
                    ownerFilters.approval_status === OwnerApprovalStatusEnum.pending ? '1rem' : 0
                }}
                size="small"
                onClick={handleStatusUpdate(OwnerApprovalStatusEnum.approved, owner.username)}
              >
                Genehmigen
              </Button>
            </Grid>
          ) : null}
          {ownerFilters.approval_status !== OwnerApprovalStatusEnum.rejected ? (
            <Grid item>
              <Button
                variant="contained"
                sx={{ minWidth: '10rem' }}
                size="small"
                onClick={handleStatusUpdate(OwnerApprovalStatusEnum.rejected, owner.username)}
              >
                Ablehnen
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  )

  return (
    <Container>
      <Typography variant="h4" color="primary.dark">
        Registrierungsanfragen
      </Typography>
      <StyledTextField
        select
        onChange={handleStatusFiltering}
        defaultValue="pending"
        size="small"
        sx={{
          width: '15rem',
          marginTop: '1rem',
          marginBottom: '1.5rem',
          color: 'primary.light',
          '& .MuiOutlinedInput-root': {
            color: 'secondary.contrastText'
          }
        }}
      >
        <MenuItem sx={{ color: 'primary.light' }} value="pending">
          Ausstehende Genehmigungen
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="approved">
          Zugelassene Benutzer
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="rejected">
          Abgelehnte Benutzer
        </MenuItem>
      </StyledTextField>

      {isLoading ? (
        <Box>wird geladen...</Box>
      ) : owners?.items && owners?.items.length > 0 ? (
        <>
          {owners?.items.map(renderUserCards)}

          <Box
            mt="1.5rem"
            sx={{
              width: '100%',
              height: '2.875rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Typography
              color="primary.light"
              sx={{ position: 'absolute', left: 0 }}
            >{`${owners.total} Anzeigen insgesamt`}</Typography>

            {owners.total > pagination.limit ? (
              <Box sx={{ justifyContent: 'center' }}>
                {pageNumber > 1 ? (
                  <IconButton onClick={handlePageChange('previous')}>
                    <ArrowBackIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
                  </IconButton>
                ) : null}
                <>
                  {pageNumber} / {Math.ceil(owners.total / pagination.limit)}
                </>
                {pageNumber < owners.total / pagination.limit ? (
                  <IconButton onClick={handlePageChange('next')}>
                    <ArrowForwardIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
                  </IconButton>
                ) : null}
              </Box>
            ) : null}
          </Box>
        </>
      ) : (
        <Typography variant="h4">Keine Benutzer gefunden</Typography>
      )}
    </Container>
  )
}
