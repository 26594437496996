import React, { useState, useRef } from 'react'

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import { Box, Button } from '@mui/material'
import { CropperProps } from '.'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export const Cropper = ({ onUpload }: CropperProps) => {
  const [imgSrc, setImgSrc] = useState('')
  const [file, setFile] = useState<File | null>()
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const blobUrlRef = useRef('')
  const [showPreviewCanvas, setShowPreviewCanvas] = useState(true) // Add this state variable
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleButtonClick = () => {
    if (fileInputRef && fileInputRef.current) fileInputRef.current.click()
  }

  const [crop, setCrop] = useState<Crop | undefined>({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 250,
    height: 186
  })
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      setFile(e.target.files[0])
      setCrop(undefined) // Makes crop preview update between images.
      setShowPreviewCanvas(true)
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 250 / 180))
  }

  const onUploadCropClick = () => {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob(blob => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }

      file ? onUpload([file]) : null
      setFile(null)
      setImgSrc('')
      setShowPreviewCanvas(false)
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0)
      }
    },
    100,
    [completedCrop, 1, 0]
  )

  return (
    <Box>
      <Box className="Crop-Controls">
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          ref={fileInputRef}
          style={{ display: 'none' }} // Hide the default file input
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'primary.dark',
            color: 'white',
            marginBottom: '10px',
            border: 'none',
            padding: '10px 20px',
            cursor: 'pointer'
          }}
          onClick={handleButtonClick}
        >
          Bild Wählen
        </Button>
      </Box>

      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={c => setCrop(c)}
          onComplete={c => setCompletedCrop(c)}
          aspect={250 / 186}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${1}) rotate(${0}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {showPreviewCanvas
        ? !!completedCrop && (
            <>
              <Box sx={{ marginTop: '10px' }}>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px solid black',
                    objectFit: 'contain',
                    width: completedCrop.width,
                    height: completedCrop.height
                  }}
                />
              </Box>
            </>
          )
        : null}
      <Box sx={{ marginTop: '10px' }}>
        {file ? (
          <Button
            variant="contained"
            onClick={onUploadCropClick}
            sx={{
              backgroundColor: 'primary.dark',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              cursor: 'pointer'
            }}
          >
            Bild Hochladen
          </Button>
        ) : null}
      </Box>
    </Box>
  )
}
