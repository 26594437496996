import { Box, Container, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { MapSwitch } from '~components/mapButtons'
import { FilterPanel, FilterPanelInputValues } from '~components/filterPanel'

import { ListView, MapView } from '.'
import { FilterTypesEnum } from '~components/filterPanel'
import { NavLink } from 'react-router-dom'

export const Renting = () => {
  const theme = useTheme()
  const [isListView, setIsListView] = useState<boolean>(false)
  const [limit] = useState<number>(6)
  const [offset] = useState<number>(0)
  const [previous] = useState<boolean>(false)
  const [filterValues, setFilterValues] = useState<FilterPanelInputValues>({})

  const toggleViewType = () => setIsListView(prevState => !prevState)

  const handleFiltering = async (event: FilterPanelInputValues) => setFilterValues({ ...event })

  const navLinkStyle = {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  }

  return (
    <Container sx={{ position: 'relative', display: 'flex', gap: 2 }}>
      <Box
        id="sidePanel"
        component="aside"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          paddingRight: '1rem'
        }}
      >
        <MapSwitch selected={isListView} switchView={toggleViewType} />
        <FilterPanel handleFiltering={handleFiltering} category={FilterTypesEnum.listing} />

        <Box
          sx={{
            maxWidth: '12rem',
            marginTop: 'auto',
            borderRadius: '.75rem',
            padding: '1rem',
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: 'neutral.light',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}
        >
          <Typography>
            Sind Sie daran interessiert, Ihre Immobilien in die Karte kostenlos einzufügen?
          </Typography>
          <Typography>
            Alle Informationen finden Sie{' '}
            <NavLink to="/info" style={navLinkStyle}>
              hier
            </NavLink>
            .
          </Typography>
        </Box>
      </Box>

      <Box id="content" sx={{ flexGrow: 1 }} component="section">
        {isListView ? (
          <ListView pagination={{ limit, offset, previous }} listingFilters={filterValues} />
        ) : (
          <MapView locationFilters={filterValues} />
        )}
      </Box>
    </Container>
  )
}
