import { CrudTypes } from '~types/api'

export const BASE_URL = process.env.REACT_APP_BASE_URL

export const fetcher = <T>(url: string | URL, method: CrudTypes, body?: T) =>
  fetch(`${BASE_URL}/${url}`, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(body) : undefined
  })
    .then(r => r.json())
    .catch(error => {
      throw error
    })

export const fileFetcher = (url: string | URL, method: CrudTypes, body: FormData) =>
  fetch(`${BASE_URL}/${url}`, {
    method,
    body
  })
    .then(r => r.json())
    .catch(error => {
      throw error
    })
