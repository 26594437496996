import { ListingDetails, UpdateListingStatus } from '~services/listings'
import { fetcher } from '~services/utils'
import useSWRMutation from 'swr/mutation'

export const updateListingStatus =
  (listingId: number | string | undefined) =>
  async (url: string, { arg }: { arg: UpdateListingStatus }) =>
    (await fetcher(
      `listings/${listingId}/status?status=${arg.newStatus}`,
      'PATCH'
    )) as ListingDetails

export const useUpdateListingStatus = (listingId: number | undefined) => {
  const { data, trigger, error } = useSWRMutation(
    listingId ? `listings/${listingId}/status` : null,
    updateListingStatus(listingId)
  )

  return {
    listing: data,
    error,
    trigger
  }
}
