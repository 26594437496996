import { Box, Container, Link, Typography } from '@mui/material'
import { Image } from 'mui-image'
import logoBig from '~assets/images/logo-big-575x711.png'

export const RegistrationConfirmation = () => {
  return (
    <Container component="section" sx={{ display: 'flex' }}>
      <Image style={{ width: '36rem' }} src={logoBig} wrapperStyle={{ flexBasis: 'content' }} />
      <Box
        id="formWrapper"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginY: '4rem'
        }}
      >
        <Typography variant="h4" color="primary.dark" sx={{ my: '1rem' }}>
          Anmeldeportal
        </Typography>
        <Typography variant="h3" color="primary.dark" sx={{ mt: '5rem', mb: '1rem' }}>
          Der Antrag wurde eingereicht.
        </Typography>
        <Typography>Vielen Dank für das Absenden des Anmeldeformulars!</Typography>
        <Typography>Das vorläufige Passwort wurde an Ihre E-Mail geschickt.</Typography>
        <Typography>
          Bitte beachten Sie, dass Sie nicht sofort Zugang zu Ihrer persönlichen Kontoseite
          erhalten.
        </Typography>
        <Typography variant="h5" sx={{ my: '1rem' }}>
          Bitte beachten Sie, dass Sie nicht sofort Zugang zu Ihrer persönlichen Kontoseite
          erhalten.
        </Typography>
        <Typography>1. Ihr Antrag auf ein Konto wird vom Administrator geprüft.</Typography>
        <Typography>
          2. Nach der Genehmigung erhalten Sie eine E-Mail vom Administrator und haben Zugang zur
          Verwaltung des Eintrags.
        </Typography>
        <Typography sx={{ mt: '1rem' }}>
          Bei Fragen wenden Sie sich bitte an die folgende E-Mail-Adresse:{' '}
          <Link href={'mailto:xxxx@traunreut.de'}>xxxx@traunreut.de</Link>.
        </Typography>
        <Typography>
          Bitte überprüfen Sie die{' '}
          <Link href="/info" color="primary.dark">
            Infoblatt-Seite
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  )
}
