import { Navigate, Route, Routes } from 'react-router-dom'

import { PageScaffold } from '~pages/PageScaffold'
import { VoucherSystem } from '~pages/voucherSystem'
import { Renting } from '~pages/renting'
import { Letting } from '~pages/letting'
import { PropertyDetailPage } from '~pages/propertyDetailPage'
import { Profile, EditProfile, MyProfile } from '~pages/profile'
import { Login } from '~pages/login'
import { Registration, RegistrationConfirmation } from '~pages/registration'
import { InfoPage } from '~pages/infoPage'
import { RequireAdmin, RequireAuth } from '~components/authGuards'
import { UserApprovalList, VacancyApprovalList } from '~components/admin'
import { MaklerVacancies } from '~components/maklerVacancies'
import { useUserRole } from '~services/owner'

export const TraunTwin = () => {
  const { userRole } = useUserRole()

  return (
    <PageScaffold>
      <Routes>
        {/*----- Public routes -----*/}
        <Route path="/" element={<Navigate to="mieten" />} />
        <Route path="mieten/:propertyId" element={<PropertyDetailPage />} />
        <Route path="mieten" element={<Renting />} />
        <Route path="stadtgutschein" element={<VoucherSystem />} />
        <Route path="info" element={<InfoPage />} />
        <Route path="login" element={<Login />} />
        <Route path="registrieren" element={<Registration />} />
        <Route path="registrieren/bestaetigung" element={<RegistrationConfirmation />} />

        {/*----- Authenticated routes -----*/}
        <Route
          path="profil"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        >
          {/*----- Admin routes -----*/}
          <Route
            path="anzeigen-verwaltung"
            element={
              <RequireAdmin userRole={userRole}>
                <VacancyApprovalList />
              </RequireAdmin>
            }
          />
          <Route
            path="benutzer-verwaltung"
            element={
              <RequireAdmin userRole={userRole}>
                <UserApprovalList />
              </RequireAdmin>
            }
          />
          <Route
            path="anzeigen-verwaltung/:propertyId"
            element={
              <RequireAdmin userRole={userRole}>
                <PropertyDetailPage />
              </RequireAdmin>
            }
          />
          {/*----- Makler routes -----*/}
          <Route path="meine-anzeigen" element={<MaklerVacancies />} />
          <Route path="meine-anzeigen/:propertyId" element={<PropertyDetailPage />} />
          <Route path="anzeige-aufgeben" element={<Letting />} />
          <Route path="mein-profil" element={<MyProfile />}>
            <Route path="bearbeiten" element={<EditProfile />} />
          </Route>
          <Route path="info" element={<InfoPage />} />
        </Route>

        {/*----- 404  -----*/}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </PageScaffold>
  )
}
