import { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import {
  AccessTime as AccessTimeIcon,
  LocalOfferOutlined as LocalOfferIcon,
  LocationOnOutlined as LocationOnIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material'
import { Pagination, OrientationEnum } from '~types/ui'
import { ReactComponent as AreaIcon } from '~assets/images/svgs/area-icon.svg'
import { CardComponent } from '~components/card'
import { ListingCard, useFilteredListings } from '~services/listings'

import { ListViewProps } from '.'

/**
 * Renders a paginated list of listings
 */
export const ListView = memo(({ pagination, listingFilters }: ListViewProps) => {
  const navigate = useNavigate()
  const [paginationState, setPaginationState] = useState<Pagination>(pagination)
  const { listings, isLoading } = useFilteredListings(listingFilters, paginationState)
  const [pageNumber, setPageNumber] = useState(1)
  const handleCardClick = (listingId: number, gmlId: string) => () =>
    navigate(`/mieten/${listingId}`, { state: { gmlId } })

  const handlePageChange = (direction: 'previous' | 'next') => () => {
    if (direction === 'previous') {
      setPaginationState(() => ({
        previous: true,
        limit: pagination.limit,
        offset: listings?.offset_previous ? listings.offset_previous : 0
      }))
      setPageNumber(pageNumber - 1)
    } else {
      setPaginationState(() => ({
        previous: false,
        limit: pagination.limit,
        offset: listings?.offset_next ? listings.offset_next : 0
      }))
      setPageNumber(pageNumber + 1)
    }
  }
  const renderCard = (listing: ListingCard) => (
    <CardComponent
      key={listing.listing_id}
      title={String(listing.title)}
      actionBtnText="Details anzeigen"
      actionBtnCallback={handleCardClick(listing.listing_id, listing.gml_id)}
      images={
        listing.attachments.length > 0
          ? listing.attachments.map(image =>
              image.s3_presigned_url ? image.s3_presigned_url : '/default.png'
            )
          : ['/default.png']
      }
      orientation={OrientationEnum.horizontal}
    >
      <Grid container direction="row" gap={2} mt={2}>
        <Grid container item direction="column" xs={6} gap={2}>
          <Grid item>
            <Grid container direction="row" gap={2} alignItems="center">
              <LocationOnIcon sx={{ color: 'primary.dark' }} />
              <Typography>
                {listing.address?.streetname_number}, {listing.address?.city}
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="row" gap={2} alignItems="center">
              <LocalOfferIcon sx={{ color: 'primary.dark' }} />
              <Typography>€{listing.price}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item direction="column" xs={5} gap={2}>
          <Grid item>
            <Grid container direction="row" gap={2} alignItems="center">
              <AreaIcon /> <Typography>{listing.usable_floor_area} m²</Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="row" gap={2} alignItems="center">
              <AccessTimeIcon sx={{ color: 'primary.dark' }} />{' '}
              <Typography>
                ab {new Date(String(listing.available_from)).toLocaleDateString('de-DE')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardComponent>
  )

  return (
    <>
      {isLoading ? (
        <Box>wird geladen...</Box>
      ) : listings?.items && listings?.items.length > 0 ? (
        <>
          <Typography
            sx={{ display: 'flex', justifyContent: 'right', marginBottom: 5, marginRight: 8 }}
            variant="h5"
            color="textSecondary"
          >
            {listings.total} results found
          </Typography>
          {listings.items.map(renderCard)}

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {pageNumber > 1 ? (
              <IconButton onClick={handlePageChange('previous')}>
                <ArrowBackIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
              </IconButton>
            ) : null}
            <>
              {pageNumber} / {Math.ceil(listings.total / paginationState.limit)}
            </>
            {pageNumber < listings.total / paginationState.limit ? (
              <IconButton onClick={handlePageChange('next')}>
                <ArrowForwardIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
              </IconButton>
            ) : null}
          </Box>
        </>
      ) : (
        <Typography variant="h4">Keine Ergebnisse für den gewählten Filter gefunden</Typography>
      )}
    </>
  )
})
