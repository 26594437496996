import useSWR from 'swr'
import { FilterPanelInputValues } from '~components/filterPanel'

import { CrudTypes } from '~types/api'

const fetcher = (url: string | URL, method: CrudTypes, headers: Headers) =>
  fetch(`${url}`, {
    method,
    headers,
    body: undefined
  }).then(r => r.json())

export const useStadtguthabenAPI = (locationFilter: FilterPanelInputValues) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data, error, isLoading } = useSWR<any[]>(
    [
      `https://api.stadtguthaben.de/vendor/arup/v1/stores`,
      'GET',
      {
        Authorization:
          'Bearer BKOy49yUfrf3Ft32ngcdoWAhPWQpyqCozGLZk05kXypnmgEZuToorRDPE2ZrluIlb-hGByYaYkBiUhdL5oFuDzVrtLnT8QKLDVPjFLHbyk0PdWFiL0mXEzq7hg7EzbTD'
      }
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ([url, method, headers]: [string, 'GET', any]) => fetcher(url, method, headers)
  )

  const filteredData = data?.filter(location => {
    const { gutscheine } = locationFilter
    const hasAcceptor = location.filter.acceptor
    const hasSeller = location.filter.seller

    switch (gutscheine) {
      case 'both':
        return hasAcceptor && hasSeller
      case 'accepts':
        return hasAcceptor
      case 'sells':
        return hasSeller
      default:
        // Include all data when gutscheine is not specified or 'either'
        return true
    }
  })

  return {
    filteredData,
    error,
    isLoading
  }
}
