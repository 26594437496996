import { Box, Card, Grid, Paper, Typography, Chip } from '@mui/material'
import { Map as MapIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import { OrientationEnum } from '~types/ui'

import { CardComponentProps } from '.'

export const CardComponentVoucher = ({
  orientation,
  title,
  labels,
  children,
  popup,
  closeWindow
}: CardComponentProps) => {
  const theme = useTheme()

  const renderLabels = (label: string) => (
    <Chip
      key={label}
      label={label}
      variant="outlined"
      size="small"
      sx={{
        color: 'primary.main',
        border: 'solid 1px',
        borderColor: 'primary.main',
        borderRadius: '.5rem'
      }}
    />
  )

  const paperStyling = {
    maxWidth: orientation === OrientationEnum.horizontal ? '908px' : '380px',
    border: 'solid 1px',
    overflow: 'hidden',
    borderColor: 'neutral.light',
    borderRadius: '.75rem',
    marginBottom: '2rem'
  }

  return (
    <Paper sx={paperStyling} elevation={8}>
      <Card
        sx={{
          position: 'relative',
          padding: '2rem',
          borderRadius: '.75rem',
          display: 'flex',
          flexDirection: orientation === OrientationEnum.horizontal ? 'row' : 'column'
        }}
      >
        {popup ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              cursor: 'pointer',
              paddingBottom: 2,
              color: 'primary.dark',
              zIndex: 2,
              fontSize: 20,
              fontWeight: 'bold'
            }}
            onClick={closeWindow}
          >
            x
          </Box>
        ) : null}
        <Grid container direction="column" spacing={4}>
          {/*----- Card title -----*/}
          <Grid item>
            <Typography variant="h3" color="primary.dark" sx={{ marginBottom: '1rem' }}>
              {title}
            </Typography>

            {/*----- Card tags -----*/}
            <Grid container gap={2} mb="1rem">
              <Grid item xs={6} display="flex" gap={1}>
                {labels && labels.length > 0 && labels.map(renderLabels)}
              </Grid>

              {/*----- Go to map view button -----*/}
              {orientation === OrientationEnum.horizontal && (
                <Grid item xs={5} display="flex">
                  <Chip
                    label={'Auf Karte zeigen'}
                    icon={<MapIcon sx={{ fill: theme.palette.primary.dark }} fontSize="small" />}
                    variant="outlined"
                    size="small"
                    sx={{
                      color: 'primary.dark',
                      border: 'solid 1px',
                      borderColor: 'primary.dark',
                      borderRadius: '.5rem'
                    }}
                  />
                </Grid>
              )}
            </Grid>

            {/*----- Card Content -----*/}
            <Box display="flex" flexWrap="wrap">
              {children}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Paper>
  )
}
