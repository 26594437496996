import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'

import { TraunTwin } from '~pages/router'

import theme from './theme'
import '@aws-amplify/ui-react/styles.css'

import { AWSConfig } from '~config/awsConfig'

Amplify.configure(AWSConfig)

export const App = () => (
  <Authenticator.Provider>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TraunTwin />
      </ThemeProvider>
    </BrowserRouter>
  </Authenticator.Provider>
)
