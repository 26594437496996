import useSWR from 'swr'

import { PaginatedResponse, Pagination } from '~types/ui'
import { Owner, OwnerFilters } from '~services/owner'
import { fetcher } from '~services/utils'

export const useOwners = (
  ownerFilters: OwnerFilters = {},
  { limit, offset, previous }: Pagination = { limit: 6, offset: 0, previous: false }
) => {
  const { data, isLoading, error, mutate } = useSWR<PaginatedResponse<Owner[]>>(
    [
      `owners/?limit=${limit}&offset=${offset}&previous=${previous}${
        ownerFilters.role ? `&role=${ownerFilters.role}` : ''
      }${ownerFilters.approval_status ? `&approval_status=${ownerFilters.approval_status}` : ''}`
    ],
    ([url]: [string]) => fetcher(url, 'GET')
  )

  return {
    owners: data,
    isLoading,
    error,
    mutate
  }
}
