import { Menu, MenuItem, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { UserRoleEnum, useUserRole } from '~services/owner'

import { type AccountMenuProps } from '.'

export const AccountMenu = ({ isOpen, anchor, closeMenu }: AccountMenuProps) => {
  const navigate = useNavigate()
  const { signOut, user } = useAuthenticator(context => [context.user])
  const { userRole } = useUserRole()

  const goToRoute = (route: string) => () => {
    navigate(route)
    closeMenu()
  }

  const handleLogoutUser = async () => {
    try {
      signOut()

      navigate('/mieten')
      closeMenu()
    } catch (error) {
      console.error('Logout unsuccessful: ', error)
    }
  }

  return (
    <Menu id="accountMenu" open={isOpen} anchorEl={anchor}>
      {user && userRole === UserRoleEnum.Admin
        ? [
            <MenuItem key="Alle Anzeigen" onClick={goToRoute('/profil/anzeigen-verwaltung')}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Alle Anzeigen
              </Typography>
            </MenuItem>,
            <MenuItem key="Alle Benutzer" onClick={goToRoute('/profil/benutzer-verwaltung')}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Alle Benutzer
              </Typography>
            </MenuItem>,
            <MenuItem key="Ausloggen" onClick={handleLogoutUser}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Ausloggen
              </Typography>
            </MenuItem>
          ]
        : user && userRole === UserRoleEnum.Write
        ? [
            <MenuItem key="Meine Anzeigen" onClick={goToRoute('/profil/meine-anzeigen')}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Meine Anzeigen
              </Typography>
            </MenuItem>,
            <MenuItem key="Anzeige aufgeben" onClick={goToRoute('/profil/anzeige-aufgeben')}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Anzeige aufgeben
              </Typography>
            </MenuItem>,
            <MenuItem key="Mein Profil" onClick={goToRoute('/profil/mein-profil')}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Mein Profil
              </Typography>
            </MenuItem>,
            <MenuItem key="Ausloggen" onClick={handleLogoutUser}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Ausloggen
              </Typography>
            </MenuItem>
          ]
        : user && userRole === UserRoleEnum.Read
        ? [
            <MenuItem key="Ausloggen" onClick={handleLogoutUser}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Ausloggen
              </Typography>
            </MenuItem>
          ]
        : [
            <MenuItem key="Einloggen" onClick={goToRoute('/login')}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Einloggen
              </Typography>
            </MenuItem>,
            <MenuItem key="Registrieren" onClick={goToRoute('/registrieren')}>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                Registrieren
              </Typography>
            </MenuItem>
          ]}
    </Menu>
  )
}
